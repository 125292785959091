//
//
//

import { useContext } from '@nuxtjs/composition-api';
import { onSSR } from '@odyssey/core';
import { useCache, CacheTagPrefix } from '@odyssey/realtainment-output-cache';
export default {
  name: 'Home',
  components: {
    StoryblokPage: () => import(/* webpackChunkName: "chunk-page-variable-route" */ '~/components/Pages/StoryblokPage')
  },
  setup() {
    const { $config } = useContext();

    onSSR(() => {
      const { addTags } = useCache();
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
    });

    return {
      slug: $config.storyblok.homepage_slug
    };
  }
};
